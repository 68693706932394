import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IUser } from "../interfaces";

interface UserService {
  createUser: (data: {}) => Promise<IAPIResponse>;
  updateUser: (data: {}, id: string) => Promise<IAPIResponse>;
  getUsers: () => Promise<IAPIResponse<IUser[]>>;
  getAllUsers: () => Promise<IAPIResponse<IUser[]>>;
  getAdmins: () => Promise<IAPIResponse<IUser[]>>;
  getSuperAdmins: () => Promise<IAPIResponse<IUser[]>>;
  deleteUser: (id: string) => Promise<IAPIResponse>;
  getAdminsUnderSuperAdmins: (id: string) => Promise<IAPIResponse<IUser[]>>;
  getUsersUnderAdmins: (id: string) => Promise<IAPIResponse<IUser[]>>;
  getSingle: (id: string) => Promise<IAPIResponse<IUser | null>>;
}

const userService = (httpClient: AxiosInstance): UserService => {
  return {
    createUser: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/user/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User creation failed" };
      }
    },
    getUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAllUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/all-users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAdmins: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/admins"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch admins" };
      }
    },
    getSuperAdmins: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/super-admins"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    deleteUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/user/delete/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    getAdminsUnderSuperAdmins: async (id): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/super-admins/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fecth admins" };
      }
    },
    getUsersUnderAdmins: async (id): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/admins/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fecth admins" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<IUser | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.get(`/user/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    updateUser: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/user/update/${id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
  };
};

export default userService;

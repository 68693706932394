import { clearSessionStorage } from "./../utils/storage-utils";
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

import { clearStorage, getAuthStorage } from "../utils/storage-utils";
import auth from "./auth-service";
import { apiConfig } from "../config/api-config";
import record from "./records-service";
import user from "./user-service";
import activity from "./activity-service";

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<AxiosRequestConfig>) => {
    if (config.headers) {
      config.headers["x-domain-host"] = window.location.hostname || "";
    }

    const auth = getAuthStorage();
    if (auth && config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    if (!config.params) {
      config.params = {};
    }

    config.params["cache-invalidate"] = Date.now();
    return config;
  }
);

export const logout = () => {
  clearStorage();
  clearSessionStorage();
  window.location.href = `/sign-in`;
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.message === "Network Error") {
      return;
    }

    if (err.request.responseURL.includes("/api/sign-in")) {
      return;
    }

    if (
      err.response.status === 401 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      logout();
      return;
    }

    if (
      err.response.status === 403 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      logout();
      return;
    }

    throw err;
  }
);

const authService = auth(httpClient);
const recordService = record(httpClient);
const userService = user(httpClient);
const activityService = activity(httpClient);

export { authService, activityService, recordService, userService };

import jwtDecode from "jwt-decode";

import { getAuthStorage } from "./storage-utils";

export const validateToken = () => {
  const tokenData = getAuthStorage();
  let isAuth = false;
  let expiresIn = 0;
  let tempOrgId = "";
  let tempUserId = "";

  if (tokenData) {
    const { exp, orgId, userId } = jwtDecode<{
      exp: number;
      orgId: string;
      userId: string;
    }>(tokenData.token);
    expiresIn = exp * 1000;
    isAuth = Date.now() < expiresIn;
    tempOrgId = orgId;
    tempUserId = userId;
  }

  return {
    isAuth,
    expiresIn,
    role: tokenData?.role,
    orgId: tempOrgId,
    userId: tempUserId,
    name: tokenData?.name,
    email: tokenData?.email,
    image: tokenData?.image
  };
};

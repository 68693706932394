import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  Folder,
  IAPIResponse,
  ICommonResponse,
  IFolderNav,
  IFolderResponse,
  IRecordsData,
} from "../interfaces";



interface RecordService {
  addFile: (
    data: {},
    config: AxiosRequestConfig<any>
  ) => Promise<IAPIResponse<ICommonResponse>>;
  fetchRecord: (id: string) => Promise<IAPIResponse<IRecordsData>>;
  fetchRecords: (id: string) => Promise<IAPIResponse<IRecordsData[]>>;
  createFolder: (data: {}) => Promise<IAPIResponse<IFolderResponse[]>>;
  moveRecord: (data: {}) => Promise<IAPIResponse<any>>;
  updateRecord: (name: string, id: string) => Promise<IAPIResponse>;
  fetchTrash: (id: string) => Promise<IAPIResponse<IFolderResponse[]>>;
  restoreTrash: (id: string) => Promise<IAPIResponse<IFolderResponse[]>>;
  deleteRecordFromTrash: (id: string) => Promise<IAPIResponse>;
  deleteMultipleRecordsFromTrash: (ids: string[]) => Promise<IAPIResponse>;
  deleteRecord: (id: string) => Promise<IAPIResponse>;
  fetchSharingData: (id: string) => Promise<IAPIResponse<any[]>>;
  shareRecord: (data: {}) => Promise<IAPIResponse<any>>;
  updateShareRecord: (data: {}) => Promise<IAPIResponse<any>>;
  removePermission: (data: {}) => Promise<IAPIResponse<any>>;
  fetchSharedRecords: () => Promise<IAPIResponse<IRecordsData[]>>;
  fetchSharedRecordsUnderUser: (
    id: string
  ) => Promise<IAPIResponse<IRecordsData[]>>;
  fetchDashboardData: () => Promise<IAPIResponse<any>>;
  fetchRecentRecords: (limit: number) => Promise<IAPIResponse<IRecordsData[]>>;
  searchRecords: (key: string) => Promise<IAPIResponse<any>>;
  fetchParents: (
    id: string
  ) => Promise<IAPIResponse<IRecordsData[]>>;
  fetchTotalFilesUnderUser: (
    id: string
  ) => Promise<IAPIResponse<any>>;
  downloadFolder: (id: string, name: string) => Promise<boolean>;
  fetchFolders: (id: string,isPrev ?: boolean) => Promise<IAPIResponse<IFolderNav>>;
  fetchPreviousFolders: (id: string) => Promise<IAPIResponse<Folder[]>>;
}

const recordService = (httpClient: AxiosInstance): RecordService => {
  return {

    addFile: async (
      data: {},
      config
    ): Promise<IAPIResponse<ICommonResponse>> => {
      try {
        // console.log("Config",config);
        // console.log("TEst:.......\n", data);
        const res: AxiosResponse<IAPIResponse<ICommonResponse>> =
          await httpClient.post("/files", data, config);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },

    fetchRecord: async (id: string): Promise<IAPIResponse<IRecordsData>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData>> =
          await httpClient.get(`/fetch-record/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchRecords: async (id: string): Promise<IAPIResponse<IRecordsData[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/record/${id ? id : "0"}`);
        // console.log("RecordService",res.data)
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchFolders: async (id: string,isPrev=false): Promise<IAPIResponse<IFolderNav>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IFolderNav>> =
          await httpClient.post(`/get/folders/${id ? id : "0"}`,{isPrev:isPrev});
        console.log("RecordService", res.data)
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },


    fetchPreviousFolders: async (id: string): Promise<IAPIResponse<Folder[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/get/previous/folders/${id ? id : "0"}`);
        console.log("RecordService2", res.data)
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    createFolder: async (data: {}): Promise<
      IAPIResponse<IFolderResponse[]>
    > => {
      try {
        const res: AxiosResponse<IAPIResponse<IFolderResponse[]>> =
          await httpClient.post(`/record`, data);
        return res.data;
      } catch (err) {
        return { status: false, message: "Folder creation failed" };
      }
    },

    moveRecord: async (data: {}): Promise<
      IAPIResponse<any>
    > => {
      try {
        const res: AxiosResponse<IAPIResponse<IFolderResponse[]>> =
          await httpClient.post(`/move/record`, data);
        return res.data;
      } catch (err) {
        return { status: false, message: "Record move failed" };
      }
    },

    updateRecord: async (id: string, name: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/record/${id}`,
          { name }
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Update failed" };
      }
    },

    deleteRecord: async (id: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/record/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Delete failed" };
      }
    },

    fetchTrash: async (
      id: string
    ): Promise<IAPIResponse<IFolderResponse[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IFolderResponse[]>> =
          await httpClient.get(`/trash/${id}`);
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: "Failed to get trash files.",
          data: [],
        };
      }
    },

    restoreTrash: async (
      id: string
    ): Promise<IAPIResponse<IFolderResponse[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IFolderResponse[]>> =
          await httpClient.put(`/restore/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to restore.", data: [] };
      }
    },

    deleteRecordFromTrash: async (id: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/record-hard/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Delete failed" };
      }
    },

    deleteMultipleRecordsFromTrash: async (ids: string[]): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<string[] | string> = await httpClient.post('/trash/bulk-delete', { data: { docIds: ids } });
        // Assuming the response contains an array of strings or a single string indicating success or failure

        if (!res || !res.data) {
          // If response is undefined or data is not available
          return { status: false, message: "Delete failed" };
        }

        const responses = Array.isArray(res.data) ? res.data : [res.data];
        // console.log("Trash Front Res",responses);

        // Check if all responses indicate success
        const allSuccessful = responses.every(response => response === 'Record deleted successfully');

        if (allSuccessful) {
          // Return success response if all deletions were successful
          return { status: true, message: "All records deleted successfully" };
        } else {
          // If any deletion failed, return an error response
          return { status: false, message: "Some records could not be deleted" };
        }
      } catch (err) {
        // Return error response if there was an error in making the API call
        // console.log("Trash Error:", err);
        return { status: false, message: "Delete failed" };
      }
    },

    fetchSharingData
      : async (id: string): Promise<IAPIResponse<any[]>> => {
        try {
          const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
            `/record-share-users/${id}`
          );
          return res.data;
        } catch (err) {
          return {
            status: false,
            message: "Failed to get sharing data.",
            data: [],
          };
        }
      },

    shareRecord: async (data: any): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> = await httpClient.put(
          `/record-share/${data.id}`,
          {
            email: data.email,
            permission: data.permission,
          }
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to share.", data: [] };
      }
    },

    updateShareRecord: async (data: any): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> = await httpClient.put(
          `/record-share/update/${data.id}`,
          {
            email: data.email,
            permission: data.permission,
          }
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to share.", data: [] };
      }
    },

    removePermission: async (data: any): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> = await httpClient.put(
          `/record-share/delete/${data.id}`,
          {
            email: data.email,
            permission: data.permission,
          }
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to share.", data: [] };
      }
    },

    fetchSharedRecords: async (): Promise<IAPIResponse<IRecordsData[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/record-share`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchSharedRecordsUnderUser: async (
      id
    ): Promise<IAPIResponse<IRecordsData[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/user-record/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchDashboardData: async (): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> = await httpClient.get(
          `/dashboard`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to fetch dashboard data" };
      }
    },

    fetchRecentRecords: async (
      limit = 5
    ): Promise<IAPIResponse<IRecordsData[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/recent/${limit}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    searchRecords: async (key): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> = await httpClient.get(
          `/search/${key}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: "Search failed" };
      }
    },

    fetchParents: async (
      id
    ): Promise<IAPIResponse<IRecordsData[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IRecordsData[]>> =
          await httpClient.get(`/parents/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Location fetching failed" };
      }
    },

    fetchTotalFilesUnderUser: async (
      id
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any>> =
          await httpClient.get(`/user/records/total/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Total file count fetching failed" };
      }
    },

    downloadFolder: async (id: string, name: string): Promise<boolean> => {
      try {
        const response = await httpClient.post(`/download/folder/`, { folderId: id }, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true
      } catch (err) {
        console.error('Failed to download folder:', err);
        return false
        // Handle error, show an alert, etc.
      }
    },
    // downloadFolder: async (id: string): Promise<IAPIResponse<any>> => {
    //   try {
    //     const res: AxiosResponse<IAPIResponse<any>> = await httpClient.post(
    //       `/download/folder/`,
    //       {
    //         folderId: id
    //       }
    //     );
    //     return res;
    //   } catch (err) {
    //     return { status: false, message: "Failed to download." };
    //   }
    // },
  };
};

export default recordService;

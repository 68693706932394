import {
  Box,
  Breadcrumbs,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { BiHistory, BiListUl, BiShareAlt, BiTrash } from "react-icons/bi";
import SearchIcon from "@mui/icons-material/Search";
import { GrPowerReset } from "react-icons/gr";
import CustomButton from "../common/custom-buttons/CustomButton";
import IconButton from "../common/custom-buttons/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { recordService, userService } from "../../service";
import { breadCrumbState } from "../../utils/storage-utils";
import { IBreadCrumbState } from "../../interfaces";

interface IProps {
  title: string;
  onSearch?: (search: string) => void;
  buttonText?: string;
  buttonIcon?: ReactNode;
  onButtonClick?: VoidFunction;
  onDelete?: VoidFunction;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
  onView?: VoidFunction;
  onRefresh?: VoidFunction;
  onDeleteAll?: any;
}

const SubTopBar: FC<IProps> = (props) => {
  const {
    title,
    buttonText,
    buttonIcon,
    onButtonClick,
    onSearch,
    // onDelete,
    // onEdit,
    onShare,
    onView,
    onRefresh,
    onDeleteAll,
  } = props;
  const theme = useTheme();
  const location = useLocation();
  const { id, superAdminId, adminId, userId, recordId } = useParams();
  const [pageTitle, setPageTitle] = useState<IBreadCrumbState[]>([
    { id: "", name: title },
  ]);
  const [searchValue, setSearchValue] = useState("");


  const getBreadCrumps = useCallback(async (id: string) => {
    let name = await getUser(id);
    if (!name) {
      name = await getRecord(id);
    }

    const breadCrumbDatas = breadCrumbState.get();
    let index = 0;
    let flag = false;
    for (const data of breadCrumbDatas) {
      if (data.id !== id) {
        continue;
      }

      flag = true;
      index = breadCrumbDatas.indexOf(data);
    }

    if (flag) {
      const temp = [...breadCrumbDatas.slice(0, index + 1)];
      breadCrumbState.set(temp);
      setPageTitle([{ id: "", name: "files", link: "/files" }, ...temp]);
      return;
    }

    const temp = [...breadCrumbDatas, { id, name, link: location.pathname }];
    breadCrumbState.set(temp);
    setPageTitle([{ id: "", name: "files", link: "/files" }, ...temp]);
  }, [location]);

  const getValidId = useCallback(() => {
    if (id) return id;
    if (superAdminId) return superAdminId;
    if (adminId) return adminId;
    if (userId) return userId;
    if (recordId) return recordId;
    breadCrumbState.clear();
    setPageTitle([{ id: "", name: "files", link: "/files" }]);
    return null;
  }, [id, adminId, recordId, superAdminId, userId]);

  useEffect(() => {
    const validId = getValidId();
    console.log(location.pathname);
    if (
      title !== "Files" ||
      location.pathname.split("/")[1].toLowerCase() !== title.toLowerCase() ||
      !validId
    ) {
      setPageTitle([{ id: "", name: title }]);
      return;
    }

    getBreadCrumps(validId);
  }, [location.pathname, title, id, superAdminId, adminId, userId, recordId, getBreadCrumps, getValidId]);



  const getUser = async (id: string) => {
    const { status, data } = await userService.getSingle(id);
    if (!status || !data) return "";
    return data.name;
  };

  const getRecord = async (id: string) => {
    const { status, data } = await recordService.fetchRecord(id);
    if (!status || !data) return "";
    return data.name;
  };



  const resetSearch = () => {
    // Reset the search query
    setSearchValue(""); // Clear the search input value
    onSearch && onSearch(""); // Invoke the onSearch callback with an empty string
  };

  return (
    <Box
      height="75px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="25px"
      paddingRight="28px"
    >
      <Box display="inherit" alignItems="inherit" height="inherit">
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            {pageTitle.map((d, index) =>
              pageTitle.length !== index + 1 ? (
                <Link
                  underline="hover"
                  sx={{
                    textTransform: "capitalize",
                    width: "fit-content",
                    fontSize: 22,
                  }}
                  href={d.link}
                  key={index}
                >
                  {d.name}
                </Link>
              ) : (
                <Typography
                  textTransform={"capitalize"}
                  width={"fit-content"}
                  fontSize={22}
                  key={index}
                >
                  {d.name}
                </Typography>
              )
            )}
          </Breadcrumbs>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={3}>
        {onSearch && (
          <Box display="inherit" alignItems="inherit">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GrPowerReset
                size={25}
                onClick={resetSearch}
                style={{
                  marginRight: "1rem",
                  marginTop: "0.5rem",
                  cursor: "pointer",
                  color: "#2C2B83"
                }}
              />
            </Box>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                const value = e.target.value;
                setSearchValue(value); // Update the search input value
                onSearch && onSearch(value); // Invoke the onSearch callback with the value
              }}
              sx={{
                borderRadius: 4,
                background: "#F3F4F8",
                minWidth: 328,
                outline: 0,
                height: "49px",
              }}
            />
          </Box>
        )}
        {buttonText && (
          <Box display="inherit" alignItems="inherit">
            <CustomButton
              onClick={() => onButtonClick && onButtonClick()}
              sx={{
                backgroundColor: theme.colors?.colorSeven.default,
                pl: "22px",
                pr: "22px",
                borderRadius: "10px",
              }}
              startIcon={buttonIcon}
            >
              {buttonText}
            </CustomButton>
          </Box>
        )}
        <Box display="flex" gap={2.2}>
          {/* {onDelete && (
            <IconButton onClick={() => onDelete()}>
              <BiTrash fontSize={20} />
            </IconButton>
          )} */}
          {onShare && (
            <IconButton onClick={() => onShare()}>
              <BiShareAlt fontSize={20} />
            </IconButton>
          )}
          {onRefresh && ( //RESTORE
            <IconButton onClick={() => onRefresh()}>
              <BiHistory fontSize={20} />
            </IconButton>
          )}
          {onView && (
            <IconButton onClick={() => onView()}>
              <BiListUl fontSize={20} />
            </IconButton>
          )}
          {onDeleteAll && (
            <IconButton onClick={() => onDeleteAll()}>
              <BiTrash fontSize={20} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SubTopBar;

import React from "react";
import { Box } from "@mui/material";
import "./FileLoader.css";

const FileLoader = ({ isResponsive = false }) => {
    // const theme = useTheme();
    return (
        <div className="fullscreen">
            <Box
                sx={{
                    height: `${isResponsive ? "100%" : "100vh"}`,
                    width: `${isResponsive ? "100%" : "100vw"}`,
                    background: "#00000008",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <span className="loader"></span>
            </Box>
        </div>
    );
};

export default FileLoader;

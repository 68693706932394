import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as svg } from "./file-add.svg";

const FileAddIcon = (props: SvgIconProps) => {
  // const theme = useTheme();

  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 25 25"
      sx={{
        margin: "12px",
        fontSize: "50px",
        marginRight: "32px",
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default FileAddIcon;

import { AxiosInstance, AxiosResponse } from "axios";

import { IAPIResponse, IAuthToken } from "../interfaces";
import { setAuthStorage } from "../utils/storage-utils";

interface AuthService {
  adminSignIn: (email: string, password: string) => Promise<IAPIResponse<IAuthToken>>;
  userSignIn: (
    email: string,
    password: string,
    orgId: string
  ) => Promise<IAPIResponse<IAuthToken>>;
}

const authService = (httpClient: AxiosInstance): AuthService => {
  return {
    adminSignIn: async (
      email: string,
      password: string
    ): Promise<IAPIResponse<IAuthToken>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAuthToken>> = await httpClient.post(
          "/user/auth/sign-in",
          { username: email, password }
        );
        return res.data;
      } catch (err: any) {
        return { status: false, message: "Sign up failed try again!" };
      }
    },

    userSignIn: async (
      email: string,
      password: string,
      orgId: string
    ): Promise<IAPIResponse<IAuthToken>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAuthToken>> =
          await httpClient.post("/signIn", {
            email,
            password,
            orgId,
          });

        if (res.data.data) {
          setAuthStorage(res.data.data);
        }

        return res.data;
      } catch (err: any) {
        return { status: false, message: "Invalid password." };
      }
    },
  };
};
export default authService;

import * as React from "react";
import { BiFolderPlus } from "react-icons/bi";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import TopBar from "./TopBar";
import logo from "../../images/StudyMedic_logo.png";
import CustomButton from "../common/custom-buttons/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Typography, useTheme } from "@mui/material";
import FileAddIcon from "../common/icons/file-add/FileAddIcon";
import AddFolder from "../dialogue/AddFolderDialogue";
import { recordService } from "../../service";
import { useSnackbar } from "notistack";
import { loaderContext } from "../../context";
import { getSideBarOptions } from "../../config/app-config";
import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import FileUploadProgress from "../dialogue/FIleUploadProgress";
import { common_file_types } from "../../utils/constants";

const drawerWidth = 245;

interface Props {
  children?: React.ReactNode;
}

const ResponsiveDrawer: React.FC<Props> = (props) => {
  const { children } = props;
  const { id } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const locationObj = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  //eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [AddFolderOpen, setAddFolderOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  const { setTrigger } = React.useContext(loaderContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const sideBarOptions = getSideBarOptions();

  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const folderToggleModel = () => {
    setAddFolderOpen(!AddFolderOpen);
    handleCloseMenu();
  };

  const modalOptions = [
    {
      title: "New Folder",
      icon: <BiFolderPlus fontSize={20} />,
      action: folderToggleModel,
    },
    {
      title: "File Upload",
      icon: <FileAddIcon sx={{ fontSize: 20 }} />,
    },
  ];

  const handleAddFolder = (name: string) => {
    if (common_file_types.includes(name)) {
      snackbar(`Invalid File Name : ${name} `, { variant: "error" });
      return;
    }

    const data = {
      name,
      parentId: `${id || ""}`,
      idFolder: true,
    };

    recordService.createFolder(data).then((res) => {
      if (!res.status) {
        snackbar("Error creating folder", { variant: "error" });
        return;
      }

      setAddFolderOpen(false);
      setTrigger && setTrigger(true);
    });
    console.log(name);
  };

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getBackground = (path: string) =>
    locationObj.pathname.includes(path)
      ? { background: "#EAEEF6", color: theme.colors?.primary.default }
      : { background: "#F8F9FB", color: "#0E1C3F" };

  // const style = {
  //   position: "absolute" as "absolute",
  //   top: 142,
  //   left: 32,
  //   width: 190,
  //   bgcolor: "background.paper",
  //   boxShadow: "24",
  //   borderRadius: `0px 0px 12px 12px`,
  // };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
    // handleCloseMenu();
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleCloseMenu();
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    const files = Array.from(event.target.files);
    // Do something with the selected file
    const data = new FormData();
    // data.append("name", file.name);
    data.append("parentyId", `${id || ""}`);
    files.forEach((file) => {
      data.append("img", file);
    });
    if (id) {
      data.append("parentId", id);
    }

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded / (progressEvent.total || 0)) * 100
        );

        setProgress(percentComplete);
      },
    };

    setShowUploadProgress(true);
    recordService.addFile(data, config).then((res) => {
      setProgress(0);
      setShowUploadProgress(false);
      if (!res.status) {
        console.log("Upload Res\t\t\t", res);
        snackbar("Upload error", { variant: "error" });
        return;
      }

      snackbar("Upload success", { variant: "success" });
      setTrigger && setTrigger(true);

      // props.updateParent && props.updateParent();
    });
  };

  const drawer = (
    <Box
      className="full-height"
      sx={{
        backgroundColor: theme.colors?.colorFive.default,
      }}
    >
      <Toolbar
        sx={{
          height: 73,
        }}
      >
        <Box>
          <img style={{ width: 146, marginTop: 2 }} src={logo} alt="" />
        </Box>
      </Toolbar>
      <Divider />

      <Box sx={{ pt: 2.7, pb: 2, px: 2.5 }}>
        <CustomButton
          fullWidth
          sx={
            open
              ? {
                  background: "#EB5B91",
                  color: "white",
                  textTransform: "none",
                  fontWeight: 400,
                  borderRadius: "7.5px",
                  borderTopLeftRadius: 7.5,
                  borderTopRightRadius: 7.5,
                  borderEndStartRadius: 7.5,
                  borderEndEndRadius: 7.5,
                }
              : {
                  background: "#EB5B91",
                  color: "white",
                  textTransform: "none",
                  fontWeight: 400,

                  borderTopLeftRadius: 7.5,
                  borderTopRightRadius: 7.5,
                  borderEndStartRadius: 7.5,
                  borderEndEndRadius: 7.5,
                }
          }
          onClick={handleClick}
        >
          <AddIcon />
          <Typography
            sx={{
              fontSize: 16,
              paddingLeft: 0.5,
            }}
          >
            Create New
          </Typography>
        </CustomButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "205px",
              marginTop: "-43px",
              boxShadow: "0px 2.25px 7.5px -0.75px rgba(0, 0, 0, 0.25)",
              borderRadius: "7.5px",
            },
          }}
        >
          {modalOptions.map((d, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ background: theme.palette.common.white }}
            >
              <ListItemButton
                sx={{}}
                onClick={() =>
                  d.action ? d.action() : handleFileButtonClick()
                }
              >
                {!d.action && (
                  <input
                    id="myInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileSelected}
                    multiple={true}
                  />
                )}
                <ListItemIcon sx={{ minWidth: "35px", color: "#0E1C3F" }}>
                  {d.icon}
                </ListItemIcon>
                <ListItemText primary={d.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </Menu>
      </Box>
      <List
        sx={{
          backgroundColor: theme.colors?.colorFive.default,
        }}
      >
        {sideBarOptions.map((d, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ ...getBackground(d.url.toLowerCase()) }}
          >
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                height: "48px",
                pl: 3,
                pt: 3.3,
                pb: 3.3,
              }}
              onClick={() => navigate(d.url)}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  minWidth: "20px",
                }}
              >
                {
                  <d.icon
                    fontSize={18}
                    color={getBackground(d.url.toLowerCase()).color}
                  />
                }
              </ListItemIcon>
              <ListItemText
                primary={d.title}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 400,
                    fontSize: 16,
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }} className="full-height">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          background: "white",
          height: 75,
        }}
      >
        <TopBar />
        <Divider />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              backgroundColor: "#F8F9FB",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          pl: 4,
          paddingRight: "10px",
          pr: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ minHeight: `48px !important` }} />
        {children}
      </Box>
      {/* <Modal
        open={false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          ".MuiModal-backdrop": {
            background: "transparent",
          },
        }}
      >
        <Box sx={style}>
          <List>
            {modalOptions.map((d, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ background: theme.palette.common.white }}
              >
                <ListItemButton
                  sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                  onClick={() =>
                    d.action ? d.action() : handleFileButtonClick()
                  }
                >
                  {!d.action && (
                    <input
                      id="myInput"
                      type="file"
                      // ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelected}
                    />
                  )}
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {d.icon}
                  </ListItemIcon>
                  <ListItemText primary={d.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal> */}
      <AddFolder
        open={AddFolderOpen}
        heading={"Add New Folder"}
        submitText={"Add"}
        onSubmit={(name) => {
          handleAddFolder(name);
        }}
        onClose={() => {
          folderToggleModel();
        }}
      />
      <FileUploadProgress
        open={showUploadProgress}
        onClose={() => {
          setShowUploadProgress(false);
        }}
        progress={progress}
      />
    </Box>
  );
};

export default ResponsiveDrawer;

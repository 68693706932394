/* eslint-disable react-hooks/exhaustive-deps */
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import Root from "../components/Root";

import { apiConfig } from "../config/api-config";
// import { IAuth } from "../interfaces";
// import { IRootState } from "../interfaces/redux/store";
// import { userRoutes } from "./user-routes";
// import { adminRoutes } from "./admin-routes";
// import { PrivateRoute } from "./private-routes";
// import { useSelector } from "react-redux";
// import { Role } from "../enum";
import Template from "../components/template/Template";
import Admins from "../pages/admins/Admins";
import SuperAdmins from "../pages/super-admins/superAdmins";
import Search from "../pages/search/Search";

const UserSignIn = lazy(() => import("../pages/sign-in/SignIn"));
// const AdminSignIn = lazy(() => import("../pages/admin/sign-in/SignIn"));
const FourNotFour = lazy(() => import("../pages/FourNotFour"));
// const Home = lazy(() => import("../pages/home/home"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Files = lazy(() => import("../pages/files/Files"));
const Shared = lazy(() => import("../pages/shared/Shared"));
const Users = lazy(() => import("../pages/users/Users"));
const Activities = lazy(() => import("../pages/activity/Activities"));
const Notifications = lazy(() => import("../pages/notification/Notification"));
const Trash = lazy(() => import("../pages/trash/Trash"));
const EditProfile = lazy(() => import("../pages/edit-profile/EditProfile"));


const Routers = () => {
  // const auth = useSelector<IRootState, IAuth>(({ auth }) => auth.value);
  // const getRole = () => {
  //   switch (auth.data?.role) {
  //     case Role.ADMIN_ID:
  //       return Role.ADMIN_ID;

  //     case Role.USERS_ID:
  //     default:
  //       return Role.USERS_ID;
  //   }
  // };

  // const getRoutes = useCallback(() => {
  //   const role = getRole();
  //   if (auth.data && role === Role.ADMIN) {
  //     return adminRoutes.map((route) => ({ ...route, role: role }));
  //   }

  //   if (auth.data && auth.data.role === Role.USER) {
  //     return userRoutes.map((route) => ({ ...route, role: Role.USER }));
  //   }

  //   return [];
  // }, [auth]);

  return (
    <>
      <Routes>
        {/* <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Root>
              <PrivateRoute isIndex />
            </Root>
          }
        />

        {getRoutes().map(({ path, component, role }, index) => (
          <Route
            key={index}
            path={`${apiConfig.basePath}${path}`}
            element={
              <Template>
                <PrivateRoute component={component} access={role} />
              </Template>
            }
          />
        ))} */}
        <Route
          path={`${apiConfig.basePath}/sign-in`}
          element={<UserSignIn />}
        />
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/dashboard`}
          element={
            <Template>
              <Dashboard />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/:id`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/super-admin/:superAdminId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/admin/:adminId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/user/:userId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/user/:userId/:recordId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/shared`}
          element={
            <Template>
              <Shared />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/users`}
          element={
            <Template>
              <Users />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/admins`}
          element={
            <Template>
              <Admins />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/super-admins`}
          element={
            <Template>
              <SuperAdmins />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/activities`}
          element={
            <Template>
              <Activities />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/notification`}
          element={
            <Template>
              <Notifications />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/trash`}
          element={
            <Template>
              <Trash />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/trash/:id`}
          element={
            <Template>
              <Trash />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/search/:key`}
          element={
            <Template>
              <Search />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/edit-profile`}
          element={
            <Template>
              <EditProfile />
            </Template>
          }
        />

        {/* <Route
          path={`${apiConfig.basePath}/`}
          element={<UserSignIn />}
        /> */}
        {/* <Route
          path={`${apiConfig.basePath}/admin/sign-in/`}
          element={<AdminSignIn />}
        /> */}

        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;

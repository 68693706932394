import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { lineHeight } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { apiConfig } from "../../../config/api-config";
import { recordService } from "../../../service";
import {
  RECORD_PERMISSIONS,
  RECORD_PERMISSIONS_TYPES,
} from "../../../utils/constants";
import { IoMdRemoveCircle } from "react-icons/io";
import { isEditable } from "../../../utils/core-utils";
interface IProps {
  data: any;
  refresh: () => void;
  recordId: string;
  recordName: string;
  isFolder: boolean;
}

const PermissionListCard: FC<IProps> = ({
  data,
  refresh = () => { },
  recordId,
  recordName,
  isFolder,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [currentPermission, setCurrentPermission] = useState<string>("");
  //   console.log("DATAPERMI\t\t\t", data);
  const shareRecord = (permission: any) => {
    const payload = {
      id: recordId,
      email: data.email,
      permission,
    };
    console.log("Share_payload\t\t\t", payload);
    recordService.updateShareRecord(payload).then((res) => {
      if (!res.status) {
        console.log("REsShare\t\t", res);
        snackbar(res.message || "Something went wrong", { variant: "error" });
        return;
      }

      snackbar("Sharing updated", { variant: "success" });
      refresh();
    });
  };

  useEffect(() => {
    if (data.permission) {
      setCurrentPermission(data.permission);
    }
    console.log("data...\t", data);
  }, [data]);

  const removeUser = (data) => {
    console.log("data....dd", data);
    const payload = {
      id: recordId,
      email: data.email,
    };
    console.log("Share_payload\t\t\t", payload);
    recordService.removePermission(payload).then((res) => {
      if (!res.status) {
        console.log("REsShare\t\t", res);
        snackbar(res.message || "Something went wrong", { variant: "error" });
        return;
      }

      snackbar("Removed user access", { variant: "success" });
      refresh();
    });
  };

  return (
    <Box
      pb={1.5}
      pt={1.5}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "solid thin #e5e5e5",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        {data.permission === RECORD_PERMISSIONS_TYPES.OWNER ? (
          ""
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {<IoMdRemoveCircle
              size={20}
              style={{
                color: data.isRemovable ? "darkred" : "#ccc",
                marginRight: "1rem",
                cursor: data.isRemovable ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (!data.isRemovable) {
                  return
                }
                removeUser(data)
              }}
            />}
          </Box>
        )}
        <Box>
          {data?.image ? (
            <img
              style={{
                height: "55px",
                width: "55px",
                objectFit: "cover",
                borderRadius: "13px",
              }}
              src={`${apiConfig.s3Url}${data.image}`}
              alt="profile"
            />
          ) : (
            <Typography
              sx={{
                height: "45px",
                width: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.colors?.colorSix.default,
                borderRadius: "10px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "18px",
                color: theme.colors?.primary.default,
              }}
            >
              {data?.name?.charAt(0)}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
              }}
            >
              {data.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: theme.colors?.lightGreyText.default,
              }}
            >
              {data.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {data.permission === RECORD_PERMISSIONS_TYPES.OWNER ? (
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
            }}
          >
            {RECORD_PERMISSIONS_TYPES.OWNER}
          </Typography>
        ) : (
          <>
            {isEditable(recordName) || isFolder ?
              (<Select
                labelId="permission-select-label"
                id="permission-select"
                value={currentPermission}
                // label="Age"
                onChange={(e) => {
                  setCurrentPermission(e.target.value);
                  shareRecord(e.target.value);
                }}
                sx={{
                  width: "70px",
                  textTransform: "capitalize",
                }}
                variant="standard"
                disableUnderline
              >
                {RECORD_PERMISSIONS.map((permission, i) => (
                  <MenuItem
                    value={permission}
                    key={i}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    <>{console.log("data....3\t", data)}
                      {permission}
                    </>

                  </MenuItem>
                ))}
              </Select>) : (
                <Select
                  labelId="permission-select-label"
                  id="permission-select"
                  disabled
                  value={"view"}
                  onChange={() => { }}
                  sx={{
                    width: "70px",
                    textTransform: "capitalize",
                  }}
                  variant="standard"
                  disableUnderline
                >
                  <MenuItem
                    value={"view"}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {"view"}
                  </MenuItem>
                </Select>
              )
            }
          </>
        )}
      </Box>
    </Box>
  );
};

export default PermissionListCard;

import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import EnhancedTable from "../../components/common/custom-table/CustomTable";
import { SUPER_ADMIN_ROLE } from "../../utils/constants";
import AddUser from "../../components/addUser/AddUser";
import { userService } from "../../service";
import { IUser } from "../../interfaces";
import SubTopBar from "../../components/template/SubTopBar";
import Loader from "../../components/Loader";
import { Box } from "@mui/material";

const SuperAdmins = () => {
  const [userCreation, setUserCreation] = useState(false);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await userService.getSuperAdmins();
    setLoading(false);
    if (!res.status || !res.data) {
      console.log(res.message);
      return;
    }

    setUsersData(res.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      {userCreation ? (
        <AddUser
          cancel={() => {
            setUserCreation(false);
          }}
          refresh={() => {
            fetchUsers();
          }}
          title="Super admin"
          role={SUPER_ADMIN_ROLE}
        />
      ) : (
        <>
          <SubTopBar
            title="Super Admins"
            buttonIcon={<AddIcon />}
            buttonText="add super admin"
            onButtonClick={() => setUserCreation(true)}
          />
          <Box
            sx={{
              height: "calc(100% - 125px)",
              paddingBottom: "32px",
              paddingRight: "32px",
            }}
          >
            {loading ? (
              <Loader isResponsive />
            ) : (
              <>
                {usersData?.length ? (
                  <EnhancedTable
                    tableData={usersData}
                    refresh={() => {
                      fetchUsers();
                    }}
                    role={SUPER_ADMIN_ROLE}
                  />
                ) : (
                  <Typography>No data</Typography>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default SuperAdmins;

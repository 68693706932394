import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IActivity } from "../interfaces";

interface ActivityService {
  getActivities: (page: number, limit: number) => Promise<IAPIResponse<IActivity[]>>;
}

const activityService = (httpClient: AxiosInstance): ActivityService => {
  return {
    getActivities: async (page: number, limit: number): Promise<IAPIResponse<IActivity[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IActivity[]>> =
          await httpClient.get("/activity", { params: { page, limit } });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch activities" };
      }
    },
  };
};

export default activityService;


// import { AxiosInstance, AxiosResponse } from "axios";
// import { IAPIResponse, IActivity } from "../interfaces";

// interface ActivityService {
//   getActivities: () => Promise<IAPIResponse<IActivity[]>>;
// }

// const activityService = (httpClient: AxiosInstance): ActivityService => {
//   return {
//     getActivities: async (): Promise<IAPIResponse<IActivity[]>> => {
//       try {
//         const res: AxiosResponse<IAPIResponse<IActivity[]>> =
//           await httpClient.get("/activity");
//         return res.data;
//       } catch (error) {
//         return { status: false, message: "Failed to fetch activities" };
//       }
//     },
//   };
// };

// export default activityService;

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import { IconType } from "react-icons/lib";
import {
  Menu,
  MenuItem,
  SxProps,
  Tooltip,
  useTheme,
  Modal,
} from "@mui/material";
import { CardType } from "../../enum";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { recordService } from "../../service";
import { useSnackbar } from "notistack";
import ShareRecord from "../share-record/ShareRecord";
import { IRecordsData } from "../../interfaces";
import axios from "axios";
// import auth from "../../service/auth-service";
import { getAuthStorage } from "../../utils/storage-utils";
import { useState } from "react";
import FileLoader from "../FileLoader";
// import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
import { apiConfig } from "../../config/api-config";
import { isEditable } from "../../utils/core-utils";
import FileDownloadProgress from "../dialogue/FIleDownloadProgress";

interface IProps {
  id?: string;
  record?: IRecordsData;
  title: string;
  dIcon: React.ReactNode;
  cardColor?: string;
  sx?: SxProps;
  type?: CardType;
  dense?: boolean;
  clickHandler?: VoidFunction;
  clickSelection?: VoidFunction;
  fetchRecords?: VoidFunction;
  isDelete?: boolean;
  isEdit?: boolean;
  isSelected?: boolean;
  onDelete?: (id: string) => void;
  isRestore?: boolean;
  onRestore?: (id: string) => void;
  setInitialLoadingComplete?: (val: boolean) => void;
  allowShare?: boolean;
  allowRename?: boolean;
  handleRenameTrigger?: () => void;
  allowLocation?: boolean;
  allowDownload?: boolean;
  isTrash?: boolean;
  isMove?: boolean;
  handleMove?: (record: IRecordsData) => void;
  isSuperAdmin?: boolean;
}

const FolderCardComponent: React.FC<IProps> = ({
  id,
  record,
  title,
  dIcon,
  cardColor,
  sx,
  type,
  dense,
  clickHandler,
  clickSelection,
  fetchRecords,
  isDelete = true,
  isEdit = isEditable(title),
  isSelected,
  onDelete,
  isRestore,
  onRestore,
  setInitialLoadingComplete,
  allowShare,
  allowRename,
  handleRenameTrigger,
  allowLocation,
  allowDownload,
  isTrash = false,
  isMove = false,
  handleMove,
  isSuperAdmin = false,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSharingBox, setSharingBox] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [fileLoading, setfileLoading] = useState(false);
  const [rename, setRename] = useState(false);
  const [newName, setNewName] = useState(title);
  const [trigger, setTrigger] = useState(false);
  // const navigate = useNavigate();
  const [folderModal, openFolderModal] = useState<boolean>(false);
  const [folder, setFolder] = useState<IRecordsData | null>(null);
  const [progress, setProgress] = useState(false);

  const [currentUser, setCurrentUser] = useState<string | undefined>("");


  React.useEffect(() => {
    if (!anchorEl) {
      setOpen(false);
      return;
    }
    setOpen(true);
  }, [anchorEl]);

  const [currentSession, setCurrentSession] = useState<any>();
  React.useEffect(() => {
    const session = getAuthStorage();
    setCurrentSession(session);
    setCurrentUser(session?.userId);
  }, []);


  const checkAdminEditable = (): boolean => {
    if (isSuperAdmin) {
      return true
    }
    // const status: boolean = currentSession?.role === "admin" && record?.owner === currentSession.userId;
    const status: boolean = currentSession?.role === "admin";

    // console.log("Status\t\t", isSuperAdmin, status, '\n', record);
    return status
  };


  React.useEffect(() => { }, [trigger]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [downloadModal, openDownloadModal] = useState(false);

  const handleCloseDownload = () => {
    setAnchorEl(null);
    openDownloadModal(!downloadModal);
  };

  const handleDownload = () => {
    openDownloadModal(true);
  };

  const handleCheckEdit = () => {
    if (record?.owner === currentUser) {
      return true;
    } else {
      if (record?.permissions[0]?.permission === "edit") {
        return true;
      } else {
        return false;
      }
    }
  };

  // const handleImage = async (file) => {
  //   console.log("image", file);

  //   const fileName = file.substring(file.lastIndexOf("/") + 1);
  //   console.log("imagename", fileName);

  //   const apiUrl =
  //     apiConfig.serverURL + `/api/app/get/signurl?param=${fileName}`;
  //   console.log("URL:", apiUrl);

  //   try {
  //     const response = await axios.get(apiUrl);
  //     const previewUrl = response.data.url;
  //     console.log("previewUrl", previewUrl);
  //     return previewUrl;
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setfileLoading(false);
  //   }
  // };

  const [progressBar, openProgressBar] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const handleProgressClose = () => {
    openProgressBar(false);
  }

  const downloadFile = (previewUrl, fileName) => {
    openProgressBar(true);
    const xhr = new XMLHttpRequest();
    xhr.open("GET", previewUrl);
    xhr.responseType = "blob";

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = ((event.loaded / event.total) * 100).toFixed(2);
        console.log(`Downloading... ${percent}%`);
        setCurrentProgress(parseInt(percent, 10));
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        console.log("Download complete");
      } else {
        console.error("Error downloading file");
      }
    };

    xhr.onerror = () => {
      console.error("Error downloading file");
    };

    xhr.send();
  };

  const handleConfirm = async () => {
    setfileLoading(true);
    const file = record?.img;
    const fileName = file?.substring(file.lastIndexOf("/") + 1);
    console.log("imagename", fileName, record);
    const apiUrl =
      apiConfig.serverURL + `/api/app/get/signurl?param=${fileName}`;
    console.log("URL:", apiUrl);
    try {
      const response = await axios.get(apiUrl);
      const previewUrl = response.data.url;
      console.log("previewUrl", previewUrl);
      downloadFile(previewUrl, record?.name);
      return;
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setfileLoading(false);
      handleCloseDownload();
    }
  };

  const handleDelete = (id: string) => {
    setInitialLoadingComplete && setInitialLoadingComplete(false);
    recordService.deleteRecord(id).then((res) => {
      setInitialLoadingComplete && setInitialLoadingComplete(true);
      if (!res.status) {
        snackbar("Delete failed", { variant: "error" });
        return;
      }
      snackbar("Delete success", { variant: "success" });
      handleClose();
      fetchRecords && fetchRecords();
    });
  };

  const handleShare = (data: {}) => {
    console.log("sharing:::::::::::::: ", id);
    setSharingBox(true);
  };

  const handleRename = () => {
    setRename(!rename);
  };

  const saveRename = async () => {
    setfileLoading(true);
    let extension = "";
    const lastIndex = title.lastIndexOf(".");
    if (lastIndex !== -1) {
      extension = title.substring(lastIndex + 1);
      // console.log("Extension\t\t", extension); // This will log 'txt'
    }
    // console.log("Extension\t\t", extension, data);
    if (newName.length === 0) {
      // snackbar("Not", { variant: "error" });
      setfileLoading(false);
      handleRename();
      return;
    }
    const removeExt = newName.replace(/\.[^/.]+$/, "");
    const validateName = removeExt.replace(/[^a-zA-Z0-9-_ ]+/g, "");
    console.log("VALIDATED\t\t\t\t", removeExt, validateName, record);
    if (id) {
      await recordService
        .updateRecord(
          id,
          record?.isFolder ? `${validateName}` : `${validateName}.${extension}`
        )
        .then((res) => {
          if (!res.status) {
            setfileLoading(false);
            snackbar("Error renaming record", { variant: "error" });
            return;
          }
          snackbar("Succefully renamed record", { variant: "success" });
          if (handleRenameTrigger) {
            handleRenameTrigger();
          }
          setfileLoading(false);
        });
    }
    handleRename();
    setTrigger(!trigger);
  };

  if (fileLoading) {
    return <FileLoader />;
  }

  const handleEdit = async () => {
    const file = record?.img;
    const fileName = file?.substring(file.lastIndexOf("/") + 1);
    const fileType = fileName
      ?.substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (!file) {
      console.log("File Error");
      return;
    }

    console.log("type", fileType);
    console.log("fileName", fileName);

    try {
      let url;

      if (fileType === "docx" || fileType === "doc") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/doc",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "pptx" || fileType === "ppt") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/slide",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "xls" || fileType === "xlsx") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/sheet",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else {
        snackbar("Unsupported file type", { variant: "error" });
        console.log("Unsupported file type:", fileType);
        throw new Error("Unsupported file type");
      }

      console.log("documentUrl", url);
      return url;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const handleFolderDownload = async (folder: any) => {
    try {
      setAnchorEl(null);
      if (!folder) {
        snackbar("Failed to download folder", { variant: "error" });
        return;
      }
      setFolder(folder);
      openFolderModal(true);
    } catch (err) {
      console.log("Error\t", err);
    }
  };

  const handleFolderConfirm = async () => {
    try {
      console.log("folder\t", folder);
      if (!folder) {
        snackbar("No folder to download", { variant: "error" });
        return;
      }
      const res = await recordService.downloadFolder(folder._id, folder.name);
      if (!res) {
        snackbar("Failed to download", { variant: "error" });
      }
    } catch (err) {
      console.log("Download error\t", err);
      snackbar("Failed to download", { variant: "error" });
    } finally {
      setFolder(null);
      openFolderModal(false);
      setProgress(false);
      return;
    }
  };

  const card = (
    <>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: dense ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          minHeight: dense ? 85 : "100%",
          padding: dense ? 2 : "0px",
          paddingBottom: dense ? `16px !important` : "8px !important",
          cursor: "pointer",
          position: "relative",
          "&:hover": {
            backgroundColor: "#E4ECFB",
          },
          backgroundColor: isSelected ? "#E4ECFB" : "none",
          boxShadow: !(isSelected && isTrash)
            ? ""
            : "rgba(44, 43, 131, 0.8) 0px 0px 60px -24px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
        }}
        onClickCapture={clickSelection}
        onDoubleClickCapture={clickHandler}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          width={dense ? "fit-content" : "100%"}
          height={dense ? "unset" : "140px"}
          alignItems="center"
          justifyContent={type === CardType.IMG ? "unset" : "center"}
        >
          {dIcon}
        </Box>
        {!rename && (
          <Tooltip title={title}>
            <Typography
              sx={{
                fontSize: "15px",
                paddingX: "14px",
                paddingBottom: dense ? "0px" : "5px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: dense ? "start" : "center",
              }}
              color="text.primary"
            // gutterBottom
            >
              {title}
            </Typography>
          </Tooltip>
        )}
        {rename && (
          <>
            <Tooltip title={title}>
              <TextField
                id="outlined-multiline-flexible"
                label={title}
                multiline
                maxRows={5}
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    border: "#EB568F",
                  },
                }}
                variant="outlined"
                sx={{
                  marginLeft: "1rem",
                }}
                value={newName} // Bind the value to the state variable
                onChange={(e) => setNewName(e.target.value)} // Update state on change
              />
            </Tooltip>
            <Button
              variant="contained"
              sx={{
                "border-radius": "0 5px 5px 0",
                height: "2.6rem",
                transform: "translateX(-3px)",
                backgroundColor: "#EB568F",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={() => saveRename()}
            >
              Save
            </Button>
          </>
        )}
        <Box
          sx={
            !dense
              ? {
                position: "absolute",
                right: 10,
                top: 10,
                ...(!isDelete || !isRestore || !anchorEl ? { display: "none" } : {}),
              }
              : {}
          }
        >
          {(isDelete || allowShare || isRestore || isEdit) && (
            <Button
              onClick={handleClick}
              sx={{
                padding: "6px",
                minWidth: "unset",
                color: theme.colors?.ternary.default,
                fontSize: "20px",
              }}
            >
              <BiDotsHorizontalRounded />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {(allowRename || checkAdminEditable()) && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  handleRename();
                }}
              >
                Rename
              </MenuItem>
            )}
            {((allowShare && record?.owner === currentUser) || checkAdminEditable()) && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  console.log("Owner\t\t", record?.owner, currentUser);
                  handleShare(id);
                  handleClose();
                }}
              >
                Share
              </MenuItem>
            )}
            {(isEdit && ((handleCheckEdit() ? true : false) || checkAdminEditable())) && (
              <MenuItem
                onClick={async () => {
                  if (!id) {
                    return;
                  }
                  try {
                    setfileLoading(true);
                    console.log("EDITRECORD\t\t\t", record);
                    const documentUrl = await handleEdit();
                    if (documentUrl) {
                      window.open(documentUrl, "_blank");
                      setfileLoading(false);
                    } else {
                      console.log("Failed to retrieve document URL");
                      setfileLoading(false);
                    }
                  } catch (error) {
                    console.error("Error:", error);
                    setfileLoading(false);
                  } finally {
                    setAnchorEl(null);
                  }
                }}
              >
                Edit
              </MenuItem>
            )}
            {isRestore && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  handleClose();
                  onRestore && onRestore(id);
                }}
              >
                Restore
              </MenuItem>
            )}
            {(isDelete || checkAdminEditable()) && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  onDelete ? onDelete(id) : handleDelete(id);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            )}
            {(isMove || checkAdminEditable()) && record && (
              <MenuItem
                onClick={() => {
                  handleMove && handleMove(record);
                  setAnchorEl(null);
                }}
              >
                Move
              </MenuItem>
            )}
            {(checkAdminEditable() || (allowDownload && currentSession?.role !== "user" && handleCheckEdit())) && (
              <MenuItem
                onClick={() => {
                  if (!record) {
                    return;
                  }
                  if (record.isFolder) {
                    handleFolderDownload(record);
                  } else {
                    handleDownload();
                  }
                }}
              >
                Download
              </MenuItem>
            )}

            {(allowLocation || checkAdminEditable()) && (
              <MenuItem onClick={() => { }}>{record?.location}</MenuItem>
            )}
          </Menu>
        </Box>
        {record && (
          <ShareRecord
            open={openSharingBox}
            onClose={() => {
              setSharingBox(false);
              handleClose();
            }}
            data={record}
          />
        )}
      </CardContent>
    </>
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          padding: "0px",
          background: cardColor || "white",
          minHeight: dense ? 64 : "100%",
          borderRadius: "10px",
          // border : "3px solid blue",
          ...{ sx },
        }}
      >
        {card}
      </Card>
      <Modal
        open={downloadModal}
        onClose={handleCloseDownload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "0.5rem",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            sx={{
              color: "#0E1C3F",
              fontWeight: 500,
              maxWidth: '100%',
            }}
          >
            Are you sure you want to download{" "}
            {/* <span
              style={{
                // textDecoration: "underline",
                fontWeight: "600",
                color: "#0E1C3F",
              }}
            >
              <br />
              {record?.name}
            </span> */}
          </Typography>
          <Typography sx={{
            fontWeight: "600",
            color: "#0E1C3F",
            maxWidth: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '12px',
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f0f0f0',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#0E1C3F',
              borderRadius: '10px',
              border: '3px solid #f0f0f0',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#0C172F',
            },
          }}>{record?.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#0E1C3F",
              mt: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                mr: "1rem",
              }}
              onClick={handleCloseDownload}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#EB5B91",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={handleConfirm}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
      {folder && (
        <Modal
          open={folderModal}
          onClose={() => openFolderModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: "0.5rem",
              boxShadow: 24,
              p: 4,
            }}
          >
            {progress && (
              <>
                <Typography
                  id="modal-modal-title"
                  sx={{
                    color: "#0E1C3F",
                    fontWeight: 500,
                    mb: "0.5rem",
                  }}
                >
                  Please wait...Download wil begin shortly..
                </Typography>
                <LinearProgress sx={{ mt: "0.5rem" }} />
              </>
            )}
            {!progress && (
              <>
                <Typography
                  id="modal-modal-title"
                  // variant="h6"
                  // component="h2"
                  sx={{
                    color: "#0E1C3F",
                    fontWeight: 500,
                  }}
                >
                  Are you sure you want to download{" "}
                  <span
                    style={{
                      // textDecoration: "underline",
                      fontWeight: "600",
                      color: "#0E1C3F",
                    }}
                  >
                    <br />
                    {record?.name}
                  </span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#0E1C3F",
                    mt: "1.5rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      mr: "1rem",
                    }}
                    onClick={() => {
                      openFolderModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#EB5B91",
                      "&:hover": {
                        backgroundColor: "#2C2B83",
                      },
                    }}
                    onClick={() => {
                      setProgress(true);
                      handleFolderConfirm();
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      )}
      {progressBar && <FileDownloadProgress open={progressBar} onClose={handleProgressClose} progress={currentProgress} />}
    </>
  );
};

export default FolderCardComponent;

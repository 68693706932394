import { allowEditTypes } from "./constants";

export const getExtensionFromRecord = (filename : string) =>{
    let extension = "";
    const lastIndex = filename.lastIndexOf(".");
    if (lastIndex !== -1) {
      extension = filename.substring(lastIndex + 1);
    }
    return extension
}


export const isEditable = (filename: string): boolean => {
    const extension = getExtensionFromRecord(filename);
    if (allowEditTypes.includes(extension)) {
        return true;
    }
    return false;
};

const processENV = process.env;
const serverURL = processENV.REACT_APP_API_URL || "/api/";
// const serverURL = "http://localhost:8383";
const basePath = processENV.REACT_APP_BASE_URL || "/";
const s3Url = processENV.REACT_APP_S3_URL || "";

export const KEY_STRING = "J8JasqoTF925nqMAjiva";

export const apiConfig = {
  baseURL: `${serverURL}/api/app/`,
  serverURL,
  timeout: 3000000,
  basePath,
  s3Url
};

export const IMAGE_COUNT = 4;

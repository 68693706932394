import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  // Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "../common/custom-buttons/CustomButton";
import { TextField } from "@mui/material";

interface IAddFolder {
  open: boolean;
  onSubmit: (folderName: string) => void;
  onClose?: VoidFunction;
  submitText: string;
  heading: string;
  submitLoading?: boolean;
  defaultTitle?: string;
}

const AddFolder: FC<IAddFolder> = ({
  open,
  onClose,
  heading,
  onSubmit,
  submitText,
  submitLoading = false,
  defaultTitle,
}) => {
  const [folderName, setFolderName] = useState<string>("");
  const [folderNameError, setFolderNameError] = useState<string>("");
  const theme = useTheme();

  const handleChange = (field: string) => {
    let validate = true;
    switch (field) {
      case "folderName":
        if (!folderName) {
          validate = false;
          setFolderNameError("Required");
          break;
        }

        if (validate) {
          setFolderNameError("");
        }

        break;

      default:
        break;
    }
  };

  const validateFields = () => {
    let validate = true;
    if (!folderName) {
      setFolderNameError("Required");
      validate = false;
    }

    return validate;
  };

  const handleUpdate = () => {
    if (!validateFields()) {
      return;
    }

    onSubmit(folderName);
  };

  useEffect(() => {
    setFolderName("");
    setFolderNameError("");
  }, [open]);

  useEffect(() => {
    if (!defaultTitle) return;

    setFolderNameError(defaultTitle);
  }, [defaultTitle, open]);

  // const getCharacterAlert = (value: string, limit: number) => {
  //   return `${value.length}/${limit} characters`;
  // };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-folderName"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "450px!important",
        },
      }}
    >
      <Box padding={1}>
        <DialogTitle
          id="alert-dialog-folderName"
          borderRadius={2}
          component={Box}
          justifyContent="space-between"
          sx={{ borderRadius: 2, display: "flex", alignItems: "center", p: 2.5 }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: theme.fontSizes.medium,
                  sm: theme.fontSizes.xl,
                },
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              {heading}
            </Typography>
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: theme.palette.common.black,
              position: "absolute",
              right: "20px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            gap: 2.5,
            display: "flex",
            flexDirection: "column",
            p:2.5,
            
          }}
        >
          <Box display="flex" flexDirection="column" gap={1} sx={{pt: 1}}>
            <TextField
              fullWidth
              label="Folder Name"
              name="folderName"
              required
              value={folderName}
              onChange={(e) => {
                setFolderNameError("");
                setFolderName(e.target.value);
              }}
              onBlur={(e) => {
                handleChange("folderName");
              }}
              error={!!folderNameError}
              helperText={folderNameError}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="end">
            <CustomButton onClick={handleUpdate}>
              {submitLoading ? "Please Wait..." : submitText}
            </CustomButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddFolder;
